import { useEffect, useState } from "react";
import { SearchBar, List, InfiniteScroll, PullToRefresh } from "antd-mobile";
import { useParams, useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import { GetAgeGroupResultsRequest } from "@/services/qr/age_group.service";
import { GetEventRequest } from "@/services/qr/event.service";

import ErrorResult from "@/components/ErrorResult";
import LoadingScreen from "@/components/LoadingScreen";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import { useHeader } from "@/context/HeaderContext";
import TimeComponent from "@/components/TimeComponent";

import { InfiniteScrollContent } from "@/components/InfiniteScrollContent";
import { TitleCollapse } from "@/components/TitleCollapse";
import Avatar from "@/components/Avatar";

export default function ParticipantResults() {
  const navigate = useNavigate();
  const { setTitle, setBack, setLive, resetContext } = useHeader();
  const [results, setResults] = useState<ParticipantResultDto[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any | null>(null);

  const { prefix, age_group_uuid } = useParams<{
    prefix: string;
    age_group_uuid: string;
  }>();

  const {
    loading: loadingAgeGroup,
    run: runAgeGroup,
    error: errorAgeGroup,
  } = useRequest(
    () => GetAgeGroupResultsRequest(age_group_uuid!!, 10, lastEvaluatedKey),
    {
      onSuccess: (data) => {
        setResults((prevResult) => {
          const allResults = [...prevResult, ...data.results];
          const uniqueResults = allResults.filter(
            (event, index, self) =>
              index === self.findIndex((e) => e.uuid === event.uuid)
          );
          return uniqueResults;
        });

        if (data.last_evaluated_key) {
          setLastEvaluatedKey(data.last_evaluated_key);
        }

        setHasMore(!!data.last_evaluated_key);
      },
      onError: (error) => {
        console.error("Error fetching events:", error);
        setHasMore(false);
      },
    }
  );

  const { run: runEvent, data: event } = useRequest(GetEventRequest, {
    manual: true,
    onError: () => console.error("Error fetching event"),
  });

  useEffect(() => {
    if (prefix) {
      runEvent(prefix);
    }
  }, [prefix, runEvent]);

  const firstResult = results?.find(() => true) as ParticipantResultDto;

  useEffect(() => {
    if (firstResult)
      setTitle(
        `${firstResult?.contest_name} (${firstResult?.age_group_name})` ?? ""
      );

    if (event) setBack(event ? `/${prefix}` : "/");

    setLive(event?.live ?? false);

    return () => {
      resetContext();
    };
  }, [prefix, setTitle, setBack, resetContext, setLive, event, firstResult]);

  if (!results?.length && errorAgeGroup) return <ErrorResult />;
  if (loadingAgeGroup || !results?.length) return <LoadingScreen />;
  if (errorAgeGroup) return <ErrorResult />;

  const handleLoadMore = async (): Promise<void> => {
    if (!loadingAgeGroup) {
      runAgeGroup();
    }
  };

  const handleClick = (participant: ParticipantResultDto) => {
    navigate(`/${prefix}/${participant.number}`);
  };

  const handleSearch = (value: string) => {
    navigate(`/${prefix}/buscar/participante?query=${value}`);
  };

  return (
    <PullToRefresh
      onRefresh={async () => {
        setResults([]);
        setLastEvaluatedKey(null);
        runAgeGroup();
      }}
    >
      <SearchBar
        placeholder="busca por nombre o número"
        onSearch={handleSearch}
      />
      <List>
        {results?.map((participant) => {
          return (
            <List.Item
              key={participant.number}
              description={<TimeComponent time={participant.total_time} />}
              prefix={
                <Avatar
                  title={participant.place_age_group.toString()}
                  circle={participant.percent_completed}
                  color={participant.participant_gender}
                  dot={participant.percent_completed < 100}
                  size="small"
                  titleSize="16px"
                />
              }
              onClick={() => handleClick(participant)}
            >
              <TitleCollapse
                title={`(${participant.number}) ${participant.participant_name}`}
              />
            </List.Item>
          );
        })}
      </List>
      <InfiniteScroll loadMore={handleLoadMore} hasMore={hasMore}>
        {(isLoading, failed) => <InfiniteScrollContent hasMore={hasMore} />}
      </InfiniteScroll>
    </PullToRefresh>
  );
}
